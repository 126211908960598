import React, { FC, useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineFire,
  AiOutlinePieChart,
} from "react-icons/all";

import { Dish, DishProduct, ProductWithAmountPerPerson } from "../../Dish";
import { Link } from "react-router-dom";
import { DishIngredient } from "./DishIngredient";

import "./BaseDishPage.scss";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";
import classNames from "classnames";

interface Props {
  dish: Dish;
  portion: number;
}

export const BaseDishPage: FC<Props> = ({ dish, portion }: Props) => {
  const [activeCard, setActiveCard] = useState<number>(0);

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div className="dish-title">{dish?.name}</div>
        <Link
          to={`/dish/edit/${dish?._id}`}
          className="btn btn-outline-primary mb-3"
        >
          <AiOutlineEdit />
        </Link>
      </div>
      <div className="d-flex mb-3 flex-wrap">
        <div className="dish-summary-text">
          <AiOutlinePieChart className="dish-summary-icon mb-1" />
          {portion.toFixed(2)}
        </div>
        <div className="dish-summary-text ml-2">
          <AiOutlineFire className="dish-summary-icon mb-1" />
          <span>{dish?.calories.toFixed()} kcal</span>
        </div>
        <div className="d-flex">
          <div className="dish-summary-text ml-2">
            B: {dish?.proteins.toFixed()} g
          </div>
          <div className="dish-summary-text ml-2">
            T: {dish?.fats.toFixed()} g
          </div>
          <div className="dish-summary-text ml-2">
            W: {dish?.carbs.toFixed()} g
          </div>
        </div>
      </div>
      <Accordion defaultActiveKey="0">
        <Card className="dish-accordion">
          <Card.Header
            className={classNames({ "card-header--active": activeCard === 0 })}
          >
            <Accordion.Toggle
              as={Button}
              variant="default"
              eventKey="0"
              onClick={() => setActiveCard(0)}
            >
              Składniki
            </Accordion.Toggle>
          </Card.Header>
          {activeCard === 0 && (
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ul className="dish-ingredients-list">
                  {dish?.products.map((x: DishProduct) => (
                    <li className="dish-element" key={x.product._id}>
                      <DishIngredient
                        id={x.product._id}
                        value={`${x.amount.toFixed()} ${
                          x.product.unit.shortcut
                        }`}
                        label={`${x.amount.toFixed()} ${
                          x.product.unit.shortcut
                        } ${x.product.name}`}
                      />
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          )}
        </Card>
        <Card className="dish-accordion">
          <Card.Header
            className={classNames({
              "card-header--active": activeCard === 1,
            })}
          >
            <Accordion.Toggle
              as={Button}
              variant="default"
              eventKey="1"
              onClick={() => setActiveCard(1)}
            >
              Składniki Asia i Szymek
            </Accordion.Toggle>
          </Card.Header>
          {activeCard === 1 && (
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Table bordered striped responsive>
                  <thead>
                    <tr>
                      <th>Produkt</th>
                      <th>Asia</th>
                      <th>Szymek</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dish?.productsWithAmountPerPerson?.map(
                      (product: ProductWithAmountPerPerson) => (
                        <tr key={dish._id}>
                          <td className="text-capitalize">
                            <DishIngredient
                              id={product.product._id}
                              value={`${product.amountAsia.toFixed()} ${
                                product.product.unit.shortcut
                              }`}
                              label={product.product.name}
                            />
                          </td>
                          <td>{product.amountAsia.toFixed(2)}</td>
                          <td>{product.amountSzymek.toFixed(2)}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          )}
        </Card>
      </Accordion>
      <>
        <div className="dish-subtitle">Sposób przygotowania:</div>
        <div
          className="dish-element"
          dangerouslySetInnerHTML={{ __html: dish?.instructions || "" }}
        />
      </>
    </>
  );
};
