import React, { FC, useCallback } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";

import { DietDay as Day } from "./DietDay";
import { DietDay } from "../diet.model";
import {
  getPolishFormatDate,
  getQueryFormatDate,
} from "../../helpers/date-helpers";
import { AiOutlineEdit } from "react-icons/ai";

interface Props {
  days: DietDay[];
  currentDate: Date;
}
export const DietTabsMobile: FC<Props> = ({ days, currentDate }: Props) => {
  const history = useHistory();

  const onSelect = useCallback(
    (eventKey) => {
      if (eventKey === "next") {
        const nextDay = getQueryFormatDate(
          new Date(new Date(currentDate).setDate(currentDate.getDate() + 1))
        );

        history.push(`/diet?from=${nextDay}&to=${nextDay}`);
      } else {
        const prevDay = getQueryFormatDate(
          new Date(new Date(currentDate).setDate(currentDate.getDate() - 1))
        );
        history.push(`/diet?from=${prevDay}&to=${prevDay}`);
      }
    },
    [currentDate]
  );

  return (
    <Tabs
      defaultActiveKey={getPolishFormatDate(currentDate)}
      id="uncontrolled-tab-example"
      onSelect={onSelect}
      className="diet-tabs"
      transition={false}
    >
      <Tab
        title={<BsChevronLeft />}
        eventKey="prev"
        tabClassName="diet-tab-nav"
      />
      {days.map((day) => (
        <Tab
          key={day._id}
          eventKey={getPolishFormatDate(new Date(day.date))}
          title={getPolishFormatDate(new Date(day.date))}
          tabClassName="text-capitalize diet-tab"
        >
          <div className="diet-tab-content">
            <Day dishes={day.dishes} id={day._id} summary={day.summary} />
          </div>
          <Link
            to={`/diet/edit/${day._id}`}
            className="btn btn-outline-primary"
          >
            <span className="mr-1">Edytuj</span>
            <AiOutlineEdit />
          </Link>
        </Tab>
      ))}
      <Tab
        title={<BsChevronRight />}
        eventKey="next"
        tabClassName="diet-tab-nav"
      >
        <span />
      </Tab>
    </Tabs>
  );
};
