import React, { FC, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useField } from "formik";

interface Option {
  name: string;
  _id: string;
}

interface Props {
  options: Option[];
  label: string;
  name: string;
}

export const Select: FC<Props> = ({ options, label, name }: Props) => {
  const [{ onChange, onBlur, value, ...field }, { error }] = useField(name);

  const handleChange = useCallback(
    ([selected]) => onChange({ target: { value: selected?._id, name } }),
    [onChange, name]
  );

  const handleBlur = useCallback(
    (e) => onBlur({ target: { ...e.target, name } }),
    [onBlur, name]
  );

  const selected = useMemo(
    () => options.filter((option: Option) => option._id === value),
    [value, options]
  );

  return (
    <Form.Group controlId={`field-${field.name}`}>
      <Form.Label>{label}</Form.Label>
      <Typeahead<Option>
        id={`field-${field.name}`}
        labelKey="name"
        placeholder="--Wybierz--"
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={!!error}
        selected={selected}
        {...field}
      />
      <Form.Control.Feedback>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
