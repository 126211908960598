import React, { FC, useMemo } from "react";
import { useField } from "formik";

import { Unit } from "../product.model";

interface Props {
  label: string;
  units: Unit[];
}

export const UnitLabel: FC<Props> = ({ label, units }: Props) => {
  const [{ value }] = useField("unit");
  const unit = useMemo(() => units.find((unit) => unit._id === value), [value]);

  return (
    <>
      {label}
      {unit && ` [${unit.defaultAmount}${unit.shortcut}]`}
    </>
  );
};
