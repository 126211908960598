import { Button, Col, Row } from "react-bootstrap";
import React, { FC, useCallback } from "react";
import { FieldArray } from "formik";
import { useParams } from "react-router";

import {
  Checkbox,
  Form,
  Input,
  RichTextEditor,
  Select,
} from "../../components";
import { Product, getAllProducts } from "../../Product";
import { editDietDay, getDietDay } from "../../Diet";
import { editDish, getDish } from "../services/dishes.service";
import { Dish } from "../models/dish.model";
import { CaloriesSummary } from "../components/CaloriesSummary";
import { useApi } from "../../hooks/use-api.hook";

import "../Dish.scss";

const CATEGORIES = {
  breakfast: "Śniadanie",
  snack: "Przekąska",
  dinner: "Obiad",
  supper: "Kolacja",
};

export const EditPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const action = useCallback(() => getDish(id), [getDietDay, id]);
  const {
    data: dish,
    isLoading: isDishLoading,
    error: dishError,
  } = useApi<Dish>({
    action,
  });

  const {
    data: products,
    isLoading: isProductLoading,
    error: productError,
  } = useApi<Product[]>({
    action: getAllProducts,
    defaultState: [],
  });

  const onSubmit = useCallback((data) => editDish(dish!._id, data), [
    dish,
    editDietDay,
  ]);

  if (isDishLoading || isProductLoading) {
    return <div>Loading...</div>;
  }

  if (dishError) {
    return <div className="text-danger">Error: {dishError.message}</div>;
  }

  if (productError) {
    return <div className="text-danger">Error: {productError.message}</div>;
  }

  if (!products || !dish) {
    return <div>404</div>;
  }

  const mappedProducts = products.map((product) => ({
    ...product,
    name: `${product.name} [${product.unit.shortcut}]`,
  }));

  const initProductsValue = dish.products.map((x) => ({
    product: x.product._id,
    amount: x.amount,
  }));

  const initialValues = {
    name: dish.name,
    instructions: dish.instructions,
    products: initProductsValue,
    categories: {
      breakfast: dish.categories.breakfast,
      snack: dish.categories.snack,
      dinner: dish.categories.dinner,
      supper: dish.categories.supper,
    },
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        isResetForm={false}
      >
        <Input type="text" name="name" label="Nazwa" />
        <FieldArray
          name="products"
          render={({ remove, insert, form }) => (
            <>
              {form.values.products.map((product: Product, index: number) => (
                <Row key={index}>
                  <Col xs="8">
                    <Select
                      name={`products.${index}.product`}
                      label="Produkt"
                      options={mappedProducts}
                    />
                  </Col>
                  <Col xs="4">
                    <Row>
                      <Col xs="6">
                        <Input
                          type="number"
                          name={`products.${index}.amount`}
                          label="Ilość"
                        />
                      </Col>
                      <Col xs="6">
                        {index > 0 && (
                          <Button
                            className="dish-remove-btn"
                            variant="danger"
                            onClick={() => remove(index)}
                          >
                            -
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
              <Button
                className="mb-3"
                variant="secondary"
                onClick={() => insert(form.values.products.length, "")}
              >
                + Dodaj kolejny produkt
              </Button>
            </>
          )}
        />
        {Object.entries(CATEGORIES).map(([key, label]) => (
          <Checkbox key={key} name={`categories.${key}`} label={label} />
        ))}
        <RichTextEditor name="instructions" label="Sposób przygotowania" />
        <div>
          <CaloriesSummary products={products} />
        </div>
        <div>
          <Button variant="primary" type="submit">
            Zapisz
          </Button>
        </div>
      </Form>
    </>
  );
};
