import React, { FC, ReactNode, useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Form } from "react-bootstrap";
import { useField } from "formik";

interface Props {
  name: string;
  label: string | ReactNode;
}

export const RichTextEditor: FC<Props> = ({ name, label }: Props) => {
  const [{ value, onChange, onBlur, ...field }, { error }] = useField(name);

  const handleChange = useCallback(
    (event, editor) => onChange({ target: { value: editor.getData(), name } }),
    [onChange, name]
  );

  const handleBlur = useCallback(
    (event, editor) => onBlur({ target: { value: editor.getData(), name } }),
    [onBlur, name]
  );

  return (
    <Form.Group controlId={`field-${field.name}`}>
      <Form.Label>{label}</Form.Label>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...field}
      />
      <Form.Control.Feedback>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
