import React, { FC, useCallback } from "react";
import { useParams } from "react-router";

import { BaseDishPage } from "../../components";
import { Dish } from "../models/dish.model";
import { getDish } from "../services/dishes.service";
import { useApi } from "../../hooks/use-api.hook";

export const DishPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const action = useCallback(() => getDish(id), [getDish, id]);
  const { data: dish, isLoading, error } = useApi<Dish>({ action });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!dish) {
    return <div>404</div>;
  }

  return <BaseDishPage dish={dish} portion={1} />;
};
