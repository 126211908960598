import React, { FC, ReactNode, useCallback } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import pl from "date-fns/locale/pl";
import { useField } from "formik";

registerLocale("pl", pl);

interface Props {
  name: string;
  label: string | ReactNode;
}

export const DatePicker: FC<Props> = ({ name, label }: Props) => {
  const [{ value, onChange, ...field }, { error }] = useField(name);

  const handleChange = useCallback(
    (value) => onChange({ target: { value, name } }),
    [onChange, name]
  );

  return (
    <Form.Group controlId={`field-${field.name}`}>
      <Form.Label>{label}</Form.Label>
      <ReactDatePicker
        locale="pl"
        placeholderText="--Wybierz--"
        dateFormat="dd/MM/yyyy"
        className="form-control"
        wrapperClassName="d-block w-100"
        selected={value}
        onChange={handleChange}
        {...field}
      />
      <Form.Control.Feedback>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
