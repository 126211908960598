import React, { FC } from "react";
import { Button } from "react-bootstrap";

import { Form, Input, Select } from "../../components";
import { addProduct, getUnits } from "../products.service";
import { Unit } from "../product.model";
import { UnitLabel } from "../components/UnitLabel";
import { useApi } from "../../hooks/use-api.hook";

export const ProductForm: FC = () => {
  const { data: units, isLoading, error } = useApi<Unit[]>({
    action: getUnits,
    defaultState: [],
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!units) {
    return <div>404</div>;
  }

  const initialValues = {
    name: "",
    unit: "",
    calories: "",
    proteins: "",
    carbs: "",
    fats: "",
  };

  return (
    <Form initialValues={initialValues} onSubmit={addProduct}>
      <Input type="text" name="name" label="Nazwa" />
      <Select name="unit" label="Jednostki" options={units} />
      <Input
        type="number"
        name="calories"
        label={<UnitLabel label="Kalorie" units={units} />}
      />
      <Input
        type="number"
        name="proteins"
        label={<UnitLabel label="Białko" units={units} />}
      />
      <Input
        type="number"
        name="fats"
        label={<UnitLabel label="Tłuszcze" units={units} />}
      />
      <Input
        type="number"
        name="carbs"
        label={<UnitLabel label="Węglowodany" units={units} />}
      />
      <Button variant="primary" type="submit">
        Dodaj nowy produkt
      </Button>
    </Form>
  );
};
