import axios from "axios";
import { DietDay, DietDayFormValues } from "./diet.model";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const addDietDay = async (diet: DietDayFormValues): Promise<DietDay> => {
  const response = await axios.post(`${apiUrl}/diet/days`, diet);
  return response.data;
};

export const getDietDay = async (id: string): Promise<DietDay> => {
  const response = await axios.get(`${apiUrl}/diet/days/${id}`);
  return response.data;
};

export const getDietDays = async (filter?: string): Promise<DietDay[]> => {
  const query = filter ? `/?date=${filter}` : "";
  const response = await axios.get(`${apiUrl}/diet/days${query}`);
  return response.data;
};

export const editDietDay = async (
  id: string,
  dish: DietDayFormValues
): Promise<DietDay> => {
  const response = await axios.put(`${apiUrl}/diet/days/${id}`, dish);
  return response.data;
};
