import React, { FC, useCallback } from "react";
import { Button } from "react-bootstrap";

import { Form, Input, Select } from "../../components";
import { editProduct, getProduct, getUnits } from "../products.service";
import { Product, Unit } from "../product.model";
import { UnitLabel } from "../components/UnitLabel";
import { useApi } from "../../hooks/use-api.hook";
import { useParams } from "react-router";

export const ProductEditForm: FC = () => {
  const { id } = useParams<{ id: string }>();

  const action = useCallback(() => getProduct(id), [getProduct, id]);
  const {
    data: product,
    isLoading: isProductLoading,
    error: productError,
  } = useApi<Product>({
    action,
  });

  const { data: units, isLoading: isUnitLoading, error: unitError } = useApi<
    Unit[]
  >({
    action: getUnits,
    defaultState: [],
  });

  const onSubmit = useCallback((data) => editProduct(product!._id, data), [
    product,
    editProduct,
  ]);

  if (isUnitLoading && isProductLoading) {
    return <div>Loading...</div>;
  }

  if (unitError) {
    return <div className="text-danger">Error: {unitError.message}</div>;
  }

  if (productError) {
    return <div className="text-danger">Error: {productError.message}</div>;
  }

  if (!units || !product) {
    return <div>404</div>;
  }

  const initialValues = {
    name: product.name,
    unit: product.unit._id,
    calories: product.calories,
    proteins: product.proteins,
    carbs: product.carbs,
    fats: product.fats,
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} isResetForm={false}>
      <Input type="text" name="name" label="Nazwa" />
      <Select name="unit" label="Jednostki" options={units} />
      <Input
        type="number"
        name="calories"
        label={<UnitLabel label="Kalorie" units={units} />}
      />
      <Input
        type="number"
        name="proteins"
        label={<UnitLabel label="Białko" units={units} />}
      />
      <Input
        type="number"
        name="fats"
        label={<UnitLabel label="Tłuszcze" units={units} />}
      />
      <Input
        type="number"
        name="carbs"
        label={<UnitLabel label="Węglowodany" units={units} />}
      />
      <Button variant="primary" type="submit">
        Zapisz
      </Button>
    </Form>
  );
};
