import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { Dish } from "../models/dish.model";
import { Link } from "react-router-dom";

interface Props {
  dish: Dish;
}

// todo to remove after upload images
const images = [
  "https://feedme-assets.s3.eu-central-1.amazonaws.com/image1.jpeg",
  "https://feedme-assets.s3.eu-central-1.amazonaws.com/image2.jpeg",
  "https://feedme-assets.s3.eu-central-1.amazonaws.com/image3.jpeg",
  "https://feedme-assets.s3.eu-central-1.amazonaws.com/image4.jpeg",
  "https://feedme-assets.s3.eu-central-1.amazonaws.com/image5.jpeg",
];

export const DishElement: FC<Props> = ({ dish }: Props) => {
  const random = Math.floor(Math.random() * images.length);

  return (
    <>
      <Link to={`/dish/${dish._id}`}>
        <Card>
          <div className="dish-card-image">
            <Card.Img variant="top" src={images[random]} />
          </div>
          <Card.Body className="dish-card-body">
            <Card.Title className="dish-card-title">{dish.name}</Card.Title>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
};
