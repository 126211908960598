import { Button, Col, Row } from "react-bootstrap";
import React, { FC } from "react";
import { FieldArray } from "formik";

import {
  Checkbox,
  Form,
  Input,
  RichTextEditor,
  Select,
} from "../../components";
import { Product, getAllProducts } from "../../Product";
import { CaloriesSummary } from "../components/CaloriesSummary";
import { addDish } from "../services/dishes.service";
import { useApi } from "../../hooks/use-api.hook";

import "../Dish.scss";

const CATEGORIES = {
  breakfast: "Śniadanie",
  snack: "Przekąska",
  dinner: "Obiad",
  supper: "Kolacja",
};

export const AddDishPage: FC = () => {
  const { data: products, isLoading, error } = useApi<Product[]>({
    action: getAllProducts,
    defaultState: [],
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!products) {
    return <div>404</div>;
  }

  const initialValues = {
    name: "",
    instructions: "",
    products: [
      {
        product: "",
        amount: "",
      },
    ],
    categories: {
      breakfast: false,
      snack: false,
      dinner: false,
      supper: false,
    },
  };

  const mappedProducts = products.map((product) => ({
    ...product,
    name: `${product.name} [${product.unit.shortcut}]`,
  }));
  return (
    <>
      <Form initialValues={initialValues} onSubmit={addDish}>
        <Input type="text" name="name" label="Nazwa" />
        <FieldArray
          name="products"
          render={({ remove, insert, form }) => (
            <>
              {form.values.products.map((product: Product, index: number) => (
                <Row key={index}>
                  <Col xs="8">
                    <Select
                      name={`products.${index}.product`}
                      label="Produkt"
                      options={mappedProducts}
                    />
                  </Col>
                  <Col xs="4">
                    <Row>
                      <Col xs="6">
                        <Input
                          type="number"
                          name={`products.${index}.amount`}
                          label="Ilość"
                        />
                      </Col>
                      <Col xs="6">
                        {index > 0 && (
                          <Button
                            className="dish-remove-btn"
                            variant="danger"
                            onClick={() => remove(index)}
                          >
                            -
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
              <Button
                className="mb-3"
                variant="secondary"
                onClick={() => insert(form.values.products.length, "")}
              >
                + Dodaj kolejny produkt
              </Button>
            </>
          )}
        />
        {Object.entries(CATEGORIES).map(([key, label]) => (
          <Checkbox key={key} name={`categories.${key}`} label={label} />
        ))}
        <RichTextEditor name="instructions" label="Sposób przygotowania" />
        <div>
          <CaloriesSummary products={products} />
        </div>
        <div>
          <Button variant="primary" type="submit">
            Dodaj nowy przepis
          </Button>
        </div>
      </Form>
    </>
  );
};
