import React, { FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

interface Props {
  type: string;
  name: string;
  label: string | ReactNode;
}

export const Input: FC<Props> = ({ type, name, label }: Props) => {
  const [field, { error }] = useField(name);

  return (
    <Form.Group controlId={`field-${field.name}`}>
      <Form.Label>{label}</Form.Label>
      <Form.Control type={type} {...field} isInvalid={!!error} />
      <Form.Control.Feedback>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
