import { Col, Row } from "react-bootstrap";
import React, { FC, useMemo } from "react";
import { useField } from "formik";

import { Input, Select } from "../../components";
import { Dish } from "../../Dish";

interface Props {
  dishes: Dish[];
  index: number;
  label: string;
}
export const DishInput: FC<Props> = ({ dishes, index, label }: Props) => {
  const [, { value: dishId }] = useField(`dishes[${index}].dish`);
  const [, { value: amountAsia }] = useField(`dishes[${index}].amountAsia`);
  const [, { value: amountSzymek }] = useField(`dishes[${index}].amountSzymek`);

  const dish = useMemo(() => dishes.find((x) => x._id === dishId), [
    dishId,
    dishes,
  ]);

  const valueAsia = (dish?.calories || 0) * amountAsia;
  const valueSzymek = (dish?.calories || 0) * amountSzymek;

  return (
    <div className="mt-4 mb-5">
      <div className="label">{label}</div>
      <Row>
        <Col xs="12" lg="6">
          <Select
            name={`dishes.${index}.dish`}
            label="Danie"
            options={dishes}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6" lg="2">
          <Input
            type="time"
            name={`dishes[${index}].time`}
            label="Godzina posiłku"
          />
        </Col>
        <Col xs="6" lg="2">
          <Input
            type="number"
            name={`dishes[${index}].amountAsia`}
            label="Porcja - Asia"
          />
        </Col>
        <Col xs="6" lg="2">
          <Input
            type="number"
            name={`dishes[${index}].amountSzymek`}
            label="Porcja - Szymek"
          />
        </Col>
      </Row>
      <Row>
        <Col xs="6" lg="2">
          <span className="dish-input-calorie-label d-inline-block font-weight-bold">
            Kalorie - Asia
          </span>
          <div className="dish-input-calorie">
            {valueAsia.toFixed(2)} [kcal]
          </div>
        </Col>
        <Col xs="6" lg="3">
          <span className="dish-input-calorie-label d-inline-block font-weight-bold">
            Kalorie - Szymek
          </span>
          <div className="dish-input-calorie">
            {valueSzymek.toFixed(2)} [kcal]
          </div>
        </Col>
      </Row>
    </div>
  );
};
