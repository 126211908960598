import React, { FC } from "react";
import { Link } from "react-router-dom";

import { useApi } from "../../hooks/use-api.hook";
import { ShoppingList } from "../shopping.model";
import { getLists } from "../shopping.service";

export const ListPage: FC = () => {
  const { data: list, isLoading, error } = useApi<ShoppingList[]>({
    action: getLists,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!list) {
    return <div>404</div>;
  }

  return (
    <>
      <div className="mb-4">Dostępne listy zakupów:</div>
      <ul>
        {list.map((element) => (
          <li key={element._id}>
            <Link to={`/list/${element._id}`}>{element.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};
