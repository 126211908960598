import React, { FC, useCallback, useEffect, useState } from "react";

import { useApi } from "../../hooks/use-api.hook";
import { ShoppingList } from "../shopping.model";
import { getSingleList, setElementDone } from "../shopping.service";
import { Element } from "../components/Element";
import { useParams } from "react-router";
import Pusher from "react-pusher";

export const SingleListPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const action = useCallback(() => getSingleList(id), [getSingleList, id]);
  const { data, isLoading, error } = useApi<ShoppingList>({
    action,
  });

  const [list, setList] = useState(data);
  useEffect(() => setList(data), [data, setList]);

  const onCheck = useCallback(
    (elementId: string, done: boolean) => setElementDone(id, elementId, done),
    [id, setElementDone]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!list) {
    return <div>404</div>;
  }

  const sortedList = list.elements.sort((a, b) =>
    a.done === b.done ? 0 : !a.done ? -1 : 1
  );

  return (
    <>
      {sortedList.map((element) => (
        <Element key={element._id} element={element} onCheck={onCheck} />
      ))}
      <Pusher channel={`lists-${id}`} event="update" onUpdate={setList} />
    </>
  );
};
