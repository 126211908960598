import { Nav, NavLink, Navbar } from "react-bootstrap";
import React, { FC, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import "./NavbarMenu.scss";

export const NavbarMenu: FC = () => {
  const history = useHistory();

  const onSelect = useCallback(
    (href, e) => {
      e.preventDefault();
      history.push(href);
    },
    [history]
  );

  return (
    <Navbar bg="light" expand="lg" sticky="top" collapseOnSelect>
      <Link to="/" className="navbar-brand">
        FeedMe
      </Link>
      <div>
        <Link to="/diet/today" className="btn btn-primary btn-diet">
          Dziś
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </div>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavLink href="/products" onSelect={onSelect}>
            Produkty
          </NavLink>
          <NavLink href="/dishes" onSelect={onSelect}>
            Przepisy
          </NavLink>
          <NavLink href="/" onSelect={onSelect}>
            Dieta
          </NavLink>
          <NavLink href="/list" onSelect={onSelect}>
            Listy zakupów
          </NavLink>
        </Nav>
        <Link to="/diet/today" className="btn btn-primary btn-diet-desktop">
          Dziś
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );
};
