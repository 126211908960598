import React, { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Dish, getAllDishes } from "../../Dish";

import { DatePicker, Form } from "../../components";
import { DietDaySummary } from "../components/DietDaySummary";
import { DishInput } from "../components/DishInput";
import { addDietDay } from "../diet.service";
import { useApi } from "../../hooks/use-api.hook";

const defaultTimes = ["07:00", "10:30", "13:30", "18:00"];

export const DietDayForm: FC = () => {
  const { data: dishes, isLoading, error } = useApi<Dish[]>({
    action: getAllDishes,
    defaultState: [],
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!dishes) {
    return <div>404</div>;
  }

  const initialValues = {
    date: "",
    dishes: new Array(4).fill(true).map((dish, index) => ({
      time: defaultTimes[index],
      dish: "",
      amountAsia: "",
      amountSzymek: "",
    })),
  };

  return (
    <Form initialValues={initialValues} onSubmit={addDietDay}>
      <Row>
        <Col xs="12" lg="6">
          <DatePicker name="date" label="Data" />
        </Col>
      </Row>
      <DishInput dishes={dishes} index={0} label="Śniadanie" />
      <DishInput dishes={dishes} index={1} label="II śniadanie" />
      <DishInput dishes={dishes} index={2} label="Obiad" />
      <DishInput dishes={dishes} index={3} label="Kolacja" />
      <div>
        <h6>Podsumowanie dnia:</h6>
        <DietDaySummary dishes={dishes} />
      </div>
      <div>
        <Button variant="primary" type="submit">
          Dodaj
        </Button>
      </div>
    </Form>
  );
};
