import React, { FC, useMemo } from "react";

interface Props {
  time: string;
  showHour?: boolean;
}

export const CategoryName: FC<Props> = ({ time, showHour }: Props) => {
  const { hours } = useMemo(() => {
    const [hours, minutes] = time.split(":");
    return { hours: Number(hours), minutes: Number(minutes) };
  }, [time]);

  if (hours < 10) {
    return (
      <div className="diet-dish-category">
        Śniadanie {showHour ? time : undefined}
      </div>
    );
  }

  if (hours < 12) {
    return (
      <div className="diet-dish-category">
        II śniadanie {showHour ? time : undefined}
      </div>
    );
  }

  if (hours < 15) {
    return (
      <div className="diet-dish-category">
        Obiad {showHour ? time : undefined}{" "}
      </div>
    );
  }

  return (
    <div className="diet-dish-category">
      Kolacja {showHour ? time : undefined}
    </div>
  );
};

CategoryName.defaultProps = {
  showHour: true,
};
