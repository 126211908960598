import React from "react";

interface Props {
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SearchComponent: React.FC<Props> = ({ onKeyUp }: Props) => {
  return (
    <form className="form-inline my-2 my-lg-0">
      <input
        onKeyUp={onKeyUp}
        className="form-control w-100"
        type="search"
        placeholder="Wyszukaj"
        aria-label="Search"
      />
    </form>
  );
};

export default SearchComponent;
