import React, { FC, useCallback } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";

import { DietDay as Day } from "./DietDay";
import { DietDay } from "../diet.model";
import {
  getPolishFormatDate,
  getQueryFormatDate,
} from "../../helpers/date-helpers";

interface Props {
  days: DietDay[];
  defaultActiveKey: string;
  dateTo: Date;
  dateFrom: Date;
}
export const DietTabs: FC<Props> = ({
  days,
  defaultActiveKey,
  dateTo,
  dateFrom,
}: Props) => {
  const history = useHistory();

  const startNextWeek = new Date(
    new Date(dateTo).setDate(dateTo.getDate() + 1)
  );

  const endNextWeek = new Date(new Date(dateTo).setDate(dateTo.getDate() + 7));

  const startPreviousWeek = new Date(
    new Date(dateFrom).setDate(dateFrom.getDate() - 7)
  );

  const endPreviousWeek = new Date(
    new Date(dateFrom).setDate(dateFrom.getDate() - 1)
  );

  const startNextWeekDate = getQueryFormatDate(new Date(startNextWeek));
  const endNextWeekDate = getQueryFormatDate(new Date(endNextWeek));
  const startPreviousWeekDate = getQueryFormatDate(new Date(startPreviousWeek));
  const endPreviousWeekDate = getQueryFormatDate(new Date(endPreviousWeek));

  const onSelect = useCallback(
    (eventKey) => {
      if (eventKey === "next") {
        history.push(`/diet?from=${startNextWeekDate}&to=${endNextWeekDate}`);
      }
      if (eventKey === "prev") {
        history.push(
          `/diet?from=${startPreviousWeekDate}&to=${endPreviousWeekDate}`
        );
      }
    },
    [
      startNextWeekDate,
      endNextWeekDate,
      startPreviousWeekDate,
      endPreviousWeekDate,
    ]
  );

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      id="uncontrolled-tab-example"
      onSelect={onSelect}
      className="diet-tabs"
      transition={false}
    >
      <Tab
        title={<BsChevronLeft />}
        eventKey="prev"
        tabClassName="diet-tab-nav"
      />
      {days.map((day) => (
        <Tab
          key={day._id}
          eventKey={getPolishFormatDate(new Date(day.date))}
          title={getPolishFormatDate(new Date(day.date))}
          tabClassName="text-capitalize diet-tab"
        >
          <div className="diet-tab-content">
            <Day dishes={day.dishes} id={day._id} summary={day.summary} />
            <Link
              to={`/diet/edit/${day._id}`}
              className="btn btn-outline-primary"
            >
              <span className="mr-1">Edytuj</span>
              <AiOutlineEdit />
            </Link>
          </div>
        </Tab>
      ))}
      <Tab
        title={<BsChevronRight />}
        eventKey="next"
        tabClassName="diet-tab-nav"
      >
        <span />
      </Tab>
    </Tabs>
  );
};
