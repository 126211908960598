import React, { FC, useCallback } from "react";

import { DietDay as Day } from "../components/DietDay";
import { DietDay } from "../diet.model";
import { getDietDays } from "../diet.service";
import { useApi } from "../../hooks/use-api.hook";
import { getQueryFormatDate } from "../../helpers/date-helpers";

export const TodayPage: FC = () => {
  const today = getQueryFormatDate(new Date());
  const action = useCallback(() => getDietDays(today), [getDietDays, today]);
  const { data: days, isLoading, error } = useApi<DietDay[]>({ action });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!days) {
    return <div>404</div>;
  }

  return (
    <>
      {days.length > 0 ? (
        days.map((day) => {
          return (
            <div className="mt-4" key={day._id}>
              <Day dishes={day.dishes} id={day._id} summary={day.summary} />
            </div>
          );
        })
      ) : (
        <div>Brak diety</div>
      )}
    </>
  );
};
