import { Col, Row } from "react-bootstrap";
import React, {FC, useCallback, useMemo} from "react";
import { useParams } from "react-router";

import { BaseDishPage } from "../../components";
import { DietDay } from "../diet.model";
import { getDietDay } from "../diet.service";
import { useApi } from "../../hooks/use-api.hook";
import { Calculator } from "../components/Calculator";

export const DietDayDishPage: FC = () => {
  const { id, dishTime } = useParams<{ id: string; dishTime: string }>();

  const action = useCallback(() => getDietDay(id), [getDietDay, id]);
  const { data: day, isLoading, error } = useApi<DietDay>({ action });

  const dish = useMemo(() => day?.dishes.find((dish) => dish.time === dishTime), [day]);
  const portion = useMemo(() => (dish?.amountSzymek || 0) + (dish?.amountAsia || 0), [dish]);

  const dishConverted = useMemo(() => {
    if (dish) {
      return {
        ...dish!.dish,
        products: dish!.dish.products.map((product) => ({
          ...product,
          amount: product.amount * portion,
        })),
        productsWithAmountPerPerson: dish!.dish.products.map((product) => ({
          ...product,
          amountAsia: product.amount * dish?.amountAsia,
          amountSzymek: product.amount * dish?.amountSzymek,
        })),
        calories: dish!.dish.calories * portion,
        carbs: dish!.dish.carbs * portion,
        fats: dish!.dish.fats * portion,
        proteins: dish!.dish.proteins * portion,
      }
    }
  }, [dish]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!day || !dish) {
    return <div>404</div>;
  }

  return (
    <Row>
      <Col xs="12">
        <BaseDishPage dish={dishConverted!} portion={portion} />
      </Col>
      <Col xs="12" className="mt-4">
        <Calculator
          portionAsia={dish.amountAsia}
          portionSzymek={dish.amountSzymek}
        />
      </Col>
    </Row>
  );
};
