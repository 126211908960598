import {
  Form as FormikForm,
  FormikHelpers,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import React, { FC, ReactNode, useCallback } from "react";

interface Props<Values extends FormikValues = FormikValues> {
  initialValues: Values;
  /* eslint-disable-next-line */
  onSubmit: (values: any) => void | Promise<any>;
  children: ReactNode;
  isResetForm?: boolean;
}

export const Form: FC<Props> = ({
  initialValues,
  children,
  onSubmit: originalOnSubmit,
  isResetForm,
}: Props) => {
  const onSubmit = useCallback(
    async (
      values: FormikValues,
      { resetForm }: FormikHelpers<FormikValues>
    ) => {
      const data = await originalOnSubmit(values);
      if (isResetForm) {
        resetForm(initialValues);
      }
      return data;
    },
    [initialValues, originalOnSubmit]
  );

  const formik = useFormik({ initialValues, onSubmit });

  return (
    <FormikProvider value={formik}>
      <FormikForm>{children}</FormikForm>
    </FormikProvider>
  );
};

Form.defaultProps = {
  isResetForm: true,
};
