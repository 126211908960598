import React, { FC } from "react";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import "./PageHeader.scss";
import SearchComponent from "../Search/Search";
import classNames from "classnames";

interface Props {
  name: string;
  link: string;
  withSearch?: boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PageHeader: FC<Props> = ({
  name,
  link,
  withSearch = false,
  onKeyUp,
}: Props) => {
  return (
    <>
      <div className={classNames("page-header", { "mb-5": !withSearch })}>
        <div className="page-header-name">{name}</div>
        <Link to={link} className="btn btn-outline-primary">
          <BsPlus />
        </Link>
      </div>
      {withSearch && (
        <div className="page-header-search">
          <SearchComponent onKeyUp={onKeyUp} />
        </div>
      )}
    </>
  );
};
