import axios from "axios";
import { ShoppingList } from "./shopping.model";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const getLists = async (): Promise<ShoppingList[]> => {
  const response = await axios.get(`${apiUrl}/lists`);
  return response.data;
};

export const getSingleList = async (id: string): Promise<ShoppingList> => {
  const response = await axios.get(`${apiUrl}/lists/${id}`);
  return response.data;
};

export const setElementDone = async (
  listId: string,
  elementId: string,
  done: boolean
): Promise<ShoppingList> => {
  const response = await axios.patch(
    `${apiUrl}/lists/${listId}/set-element-done/${elementId}`,
    {
      done,
    }
  );
  return response.data;
};
