import { Button, Col, Row } from "react-bootstrap";
import React, { FC, useCallback } from "react";
import { useParams } from "react-router";

import { DatePicker, Form } from "../../components";
import { Dish, getAllDishes } from "../../Dish";
import { editDietDay, getDietDay } from "../diet.service";
import { DietDay } from "../diet.model";
import { DietDaySummary } from "../components/DietDaySummary";
import { DishInput } from "../components/DishInput";
import { useApi } from "../../hooks/use-api.hook";

export const EditPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const action = useCallback(() => getDietDay(id), [getDietDay, id]);
  const {
    data: day,
    isLoading: isDayLoading,
    error: dayError,
  } = useApi<DietDay>({
    action,
  });

  const {
    data: dishes,
    isLoading: isDishesLoading,
    error: dishesError,
  } = useApi<Dish[]>({
    action: getAllDishes,
    defaultState: [],
  });

  const onSubmit = useCallback((data) => editDietDay(day!._id, data), [
    day,
    editDietDay,
  ]);

  if (isDayLoading || isDishesLoading) {
    return <div>Loading...</div>;
  }

  if (dayError) {
    return <div className="text-danger">Error: {dayError.message}</div>;
  }

  if (dishesError) {
    return <div className="text-danger">Error: {dishesError.message}</div>;
  }

  if (!day || !dishes) {
    return <div>404</div>;
  }

  const formDishes = day.dishes.map((x) => ({
    time: x.time,
    dish: x.dish._id,
    amountSzymek: x.amountSzymek,
    amountAsia: x.amountAsia,
  }));

  const initialValues = {
    date: new Date(day.date),
    dishes: formDishes,
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} isResetForm={false}>
      <Row>
        <Col xs="12" lg="6">
          <DatePicker name="date" label="Data" />
        </Col>
      </Row>
      <DishInput dishes={dishes} index={0} label="Śniadanie" />
      <DishInput dishes={dishes} index={1} label="II śniadanie" />
      <DishInput dishes={dishes} index={2} label="Obiad" />
      <DishInput dishes={dishes} index={3} label="Kolacja" />
      <div>
        <h6>Podsumowanie dnia:</h6>
        <DietDaySummary dishes={dishes} />
      </div>
      <div>
        <Button variant="primary" type="submit">
          Zapisz
        </Button>
      </div>
    </Form>
  );
};
