import { FormikValues, useFormikContext } from "formik";
import React, { FC, useMemo } from "react";

import { FullSummary, Summary } from "../../models/summary";
import { DietDayDishForm } from "../diet.model";
import { Dish } from "../../Dish";
import { CaloriesSummaryTable } from "../../components";

import "../Diet.scss";

interface Props {
  dishes: Dish[];
}

const getSummary = (dish: Dish, amount: number, summary: Summary) => {
  return {
    calories: summary.calories + (dish?.calories || 0) * amount,
    proteins: summary.proteins + (dish?.proteins || 0) * amount,
    fats: summary.fats + (dish?.fats || 0) * amount,
    carbs: summary.carbs + (dish?.carbs || 0) * amount,
  };
};

export const DietDaySummary: FC<Props> = ({ dishes }: Props) => {
  const { values } = useFormikContext<FormikValues>();

  const summary: FullSummary = useMemo(
    () =>
      values.dishes
        .filter(
          (x: DietDayDishForm) => x.dish && (x.amountSzymek || x.amountAsia)
        )
        .reduce(
          (prev: FullSummary, curr: DietDayDishForm) => {
            const dish = dishes.find((dish: Dish) => dish._id === curr.dish);
            if (!dish) {
              return curr;
            }

            return {
              asia: getSummary(dish, curr.amountAsia, prev.asia),
              szymek: getSummary(dish, curr.amountSzymek, prev.szymek),
            };
          },
          {
            asia: { calories: 0, proteins: 0, fats: 0, carbs: 0 },
            szymek: { calories: 0, proteins: 0, fats: 0, carbs: 0 },
          }
        ),
    [values, dishes]
  );

  return <CaloriesSummaryTable data={summary} />;
};
