import React, { FC } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";

import {
  DietDayDishPage,
  DietDayForm,
  DietPage,
  DietPerPersonPage,
  EditPage,
  TodayPage,
} from "./Diet";
import {
  AddDishPage,
  DishPage,
  DishesPage,
  EditPage as EditDishPage,
} from "./Dish";
import { ProductEditForm, ProductForm, ProductsPage } from "./Product";
import { ListPage, SingleListPage } from "./Shopping";
import { NavbarMenu } from "./NavbarMenu/NavbarMenu";

const App: FC = () => {
  return (
    <Router>
      <div className="app">
        <NavbarMenu />
        <Container className="mt-4 mb-4">
          <Switch>
            <Route path="/" exact component={DietPage} />
            <Route path="/diet/edit/:id" component={EditPage} />
            <Route path="/diet/:id/:dishTime" component={DietDayDishPage} />
            <Route path="/diet/add" component={DietDayForm} />
            <Route path="/diet/today" component={TodayPage} />
            <Route
              path="/diet/:name(asia|szymek)"
              component={DietPerPersonPage}
            />
            <Route path="/diet" component={DietPage} />
            <Route path="/dishes" component={DishesPage} />
            <Route path="/dish/edit/:id" component={EditDishPage} />
            <Route path="/dish/add" component={AddDishPage} />
            <Route path="/dish/:id" component={DishPage} />
            <Route path="/product/add" component={ProductForm} />
            <Route path="/product/edit/:id" component={ProductEditForm} />
            <Route path="/products" component={ProductsPage} />
            <Route path="/list/:id" component={SingleListPage} />
            <Route path="/list" component={ListPage} />
          </Switch>
        </Container>
      </div>
    </Router>
  );
};

export default App;
