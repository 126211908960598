import React, { FC, useCallback, useMemo } from "react";
import { getDietDays } from "../diet.service";
import { useApi } from "../../hooks/use-api.hook";
import { DietDay, DietDayDish } from "../diet.model";
import { CategoryName } from "../components/CategoryName";
import { DishProduct } from "../../Dish";
import { useParams } from "react-router";

import "../DietAsia.scss";
import {
  getPolishFormatDate,
  getQueryFormatDate,
} from "../../helpers/date-helpers";
import { useQuery } from "../../hooks/use-query.hook";

type Portion = "amountAsia" | "amountSzymek";

export const DietPerPersonPage: FC = () => {
  const { from, to } = useQuery<{ from: string; to: string }>();
  const { name } = useParams<{ name: string }>();
  const today = new Date();

  const dateFrom = useMemo(
    () =>
      new Date(
        from || new Date(today).setDate(today.getDate() - today.getDay() + 1)
      ),
    [today, from]
  );

  const dateTo = useMemo(
    () =>
      new Date(
        to || new Date(today).setDate(today.getDate() + (7 - today.getDay()))
      ),
    [today, to]
  );

  console.log(dateFrom, dateTo);
  const filters = useMemo(
    () => `${getQueryFormatDate(dateFrom)}..${getQueryFormatDate(dateTo)}`,
    [dateFrom, dateTo]
  );

  const action = useCallback(() => getDietDays(filters), [
    getDietDays,
    filters,
  ]);
  const { data: days, isLoading, error } = useApi<DietDay[]>({ action });

  const portion = `amount${name[0].toUpperCase()}${name.substring(
    1
  )}` as Portion;

  const mappedDays = useMemo(
    () =>
      days?.map((day: DietDay) => ({
        ...day,
        dishes: day.dishes.map((dish: DietDayDish) => ({
          ...dish,
          dish: {
            ...dish.dish,
            calories: dish.dish.calories * dish[portion],
            carbs: dish.dish.carbs * dish[portion],
            proteins: dish.dish.proteins * dish[portion],
            fats: dish.dish.fats * dish[portion],
            products: dish.dish.products.map((product) => ({
              ...product,
              amount: product.amount * dish[portion],
            })),
          },
        })),
      })),
    [days]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!mappedDays) {
    return <div>404</div>;
  }

  return (
    <>
      {mappedDays.map((day) => {
        return (
          <div className="mb-5 mt-2 diet-day" key={day._id}>
            <>
              <div className="text-capitalize dish-date">
                {getPolishFormatDate(new Date(day.date))}
              </div>
              <div className="dish-full-summary">
                <span>K: {day.summary.asia.calories.toFixed()} /</span>
                <div className="ml-2">
                  B: {day.summary.asia.proteins.toFixed()} g /
                </div>
                <div className="ml-2">
                  T: {day.summary.asia.fats.toFixed()} g /
                </div>
                <div className="ml-2">
                  W: {day.summary.asia.carbs.toFixed()} g
                </div>
              </div>
            </>
            {day.dishes.map(({ time, dish }: DietDayDish) => (
              <div className="diet-dish-wrapper" key={dish._id}>
                <CategoryName showHour={true} time={time} />
                <div className="text-uppercase font-weight-bold dish-name">
                  {dish.name}
                </div>
                <div className="dish-summary">
                  <span>K: {dish?.calories.toFixed()} /</span>
                  <div className="ml-2">B: {dish?.proteins.toFixed()} g /</div>
                  <div className="ml-2">T: {dish?.fats.toFixed()} g /</div>
                  <div className="ml-2">W: {dish?.carbs.toFixed()} g</div>
                </div>
                <div className="mt-3">
                  <div className="dish-name">Składniki:</div>
                  {dish?.products.map((x: DishProduct) => (
                    <div className="dish-ingredient" key={x.product._id}>
                      <span>{x.product.name} - </span>
                      <span>
                        {x.amount.toFixed()} {x.product.unit.shortcut}
                      </span>
                    </div>
                  ))}
                  <div className="mt-3 dish-prepare">
                    <div className="dish-name">Sposób przygotowania:</div>
                    <div
                      className="dish-recipe"
                      dangerouslySetInnerHTML={{
                        __html: dish?.instructions || "",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </>
  );
};
