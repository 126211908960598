import React, { FC, useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Product } from "../product.model";
import { getAllProducts } from "../products.service";
import { useApi } from "../../hooks/use-api.hook";
import { PageHeader } from "../../components";
import { AiOutlineEdit } from "react-icons/ai";

export const ProductsPage: FC = () => {
  const { data, isLoading, error } = useApi<Product[]>({
    action: getAllProducts,
    defaultState: [],
  });
  const [products, setProducts] = useState(data);
  const [filteredProducts, setFilteredProducts] = useState(data);
  useEffect(() => {
    setFilteredProducts(data);

    const sortedProducts = data?.sort((a: Product, b: Product) =>
      a.name.localeCompare(b.name)
    );
    setProducts(sortedProducts);
  }, [setProducts, setFilteredProducts, data]);

  const handleKeyUp = useCallback(
    (e) => {
      const value = e.target.value.toLowerCase();
      const newDishes = products?.filter((element: Product) =>
        element.name.toLowerCase().includes(value)
      );
      setFilteredProducts(newDishes);
    },
    [setFilteredProducts, products]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!products) {
    return <div>404</div>;
  }

  return (
    <>
      <PageHeader
        name="Produkty"
        link="/product/add"
        withSearch={true}
        onKeyUp={handleKeyUp}
      />
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>Nazwa produktu</th>
            <th>kcal</th>
            <th>B[g]</th>
            <th>T[g]</th>
            <th>W[g]</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {filteredProducts?.map((product: Product) => (
            <tr key={product._id}>
              <td>{product.name}</td>
              <td>{product.calories}</td>
              <td>{product.proteins}</td>
              <td>{product.fats}</td>
              <td>{product.carbs}</td>
              <td className="text-center">
                <Link
                  to={`product/edit/${product._id}`}
                  className="btn btn-link"
                >
                  <AiOutlineEdit />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
