import { useEffect, useState } from "react";

interface UseApiParams<Data> {
  action: () => Promise<Data>;
  defaultState?: Data;
}

interface UseApiReturn<Data> {
  data?: Data;
  isLoading: boolean;
  error?: Error;
}

export const useApi = <Data>({
  action,
  defaultState,
}: UseApiParams<Data>): UseApiReturn<Data> => {
  const [data, setData] = useState(defaultState);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    Promise.resolve(action())
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [action, setData, setLoading, setError]);

  return { data, isLoading, error };
};
