import React, { FC, useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import { DietDay } from "../diet.model";
import { getDietDays } from "../diet.service";
import { useApi } from "../../hooks/use-api.hook";
import { useQuery } from "../../hooks/use-query.hook";
import { DietTabs } from "../components/DietTabs";
import { DietTabsMobile } from "../components/DietTabsMobile";
import {
  getPolishFormatDate,
  getQueryFormatDate,
} from "../../helpers/date-helpers";
import { PageHeader } from "../../components";

export const DietPage: FC = () => {
  const { from, to } = useQuery<{ from: string; to: string }>();
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 });

  const today = useMemo(() => new Date(), []);

  const dateFrom = useMemo(
    () =>
      new Date(
        from ||
          (isMobile
            ? today
            : new Date(today).setDate(today.getDate() - today.getDay() + 1))
      ),
    [today, from, isMobile]
  );

  const dateTo = useMemo(
    () =>
      isMobile
        ? dateFrom
        : new Date(
            to ||
              new Date(today).setDate(today.getDate() + (7 - today.getDay()))
          ),
    [today, to, isMobile, dateFrom]
  );

  const isCurrentWeek = useMemo(() => today >= dateFrom && today <= dateTo, [
    today,
    dateFrom,
    dateTo,
  ]);

  const action = useCallback(
    () =>
      getDietDays(
        `${getQueryFormatDate(dateFrom)}..${getQueryFormatDate(dateTo)}`
      ),
    [getDietDays, dateTo, dateFrom]
  );
  const { data: days, isLoading, error } = useApi<DietDay[]>({ action });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!days) {
    return <div>404</div>;
  }

  return (
    <>
      <PageHeader name="Dieta" link="/diet/add" />
      {isMobile ? (
        <DietTabsMobile days={days} currentDate={dateFrom} />
      ) : (
        <DietTabs
          days={days}
          defaultActiveKey={getPolishFormatDate(
            isCurrentWeek ? today : dateFrom
          )}
          dateTo={dateTo}
          dateFrom={dateFrom}
        />
      )}
    </>
  );
};
