import { Col, Row } from "react-bootstrap";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Categories, Dish } from "../models/dish.model";
import { DishElement } from "../components/DishElement";
import { getAllDishes } from "../services/dishes.service";
import { useApi } from "../../hooks/use-api.hook";
import { PageHeader } from "../../components";
import "../Dish.scss";

const CATEGORIES = {
  breakfast: "Śniadanie",
  snack: "Przekąska",
  dinner: "Obiad",
  supper: "Kolacja",
};

export const DishesPage: FC = () => {
  const [checked, setChecked] = useState<Categories>(
    Object.keys(CATEGORIES).reduce(
      (all, key) => ({ ...all, [key]: false }),
      {}
    ) as Categories
  );

  const { data, isLoading, error } = useApi<Dish[]>({
    action: getAllDishes,
    defaultState: [],
  });

  const [dishes, setDishes] = useState(data);
  const [filteredDishes, setFilteredDishes] = useState(data);

  useEffect(() => {
    setFilteredDishes(data);
    setDishes(data);
  }, [setDishes, setFilteredDishes, data]);

  useEffect(() => {
    const allFalse = !Object.values(checked).includes(true);

    if (allFalse) {
      setDishes(data);
    } else {
      setDishes(
        data?.filter((dish: Dish) =>
          Object.entries(checked).reduce(
            (res, [key, value]) =>
              res || (value && dish.categories[key as keyof Categories]),
            false
          )
        )
      );
    }
  }, [data, checked, setDishes]);

  const onChange = useCallback(
    (event) => {
      const { value, checked: isChecked } = event.target;
      setChecked({ ...checked, [value]: isChecked });
    },
    [setChecked, checked]
  );

  const handleKeyUp = useCallback(
    (e) => {
      const value = e.target.value.toLowerCase();
      const newDishes = dishes?.filter((element: Dish) =>
        element.name.toLowerCase().includes(value)
      );
      setFilteredDishes(newDishes);
    },
    [dishes]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error.message}</div>;
  }

  if (!dishes) {
    return <div>404</div>;
  }

  return (
    <>
      <PageHeader
        name="Przepisy"
        link="/dish/add"
        onKeyUp={handleKeyUp}
        withSearch={true}
      />
      <div className="d-flex flex-wrap">
        {Object.entries(CATEGORIES).map(([key, label]) => (
          <div className="mr-4" key={key}>
            <input
              checked={checked[key as keyof Categories]}
              type="checkbox"
              id={key}
              value={key}
              onChange={onChange}
            />
            <label htmlFor={key}>{label}</label>
          </div>
        ))}
      </div>
      <div className="dish">
        <Row>
          {filteredDishes?.map((dish: Dish) => (
            <Col
              className="mt-3 mb-3"
              xs="6"
              sm="6"
              md="4"
              xl="3"
              key={dish._id}
            >
              <DishElement dish={dish} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};
