import { FormikValues, useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { Table } from "react-bootstrap";

import { DishProductForm } from "../models/dish-form.model";
import { Product } from "../../Product";
import { Summary } from "../../models/summary";

interface Props {
  products: Product[];
}

export const CaloriesSummary: FC<Props> = ({ products }: Props) => {
  const { values } = useFormikContext<FormikValues>();

  const summary: Summary = useMemo(
    () =>
      values.products
        .filter((x: DishProductForm) => x.product && x.amount)
        .reduce(
          (prev: Summary, curr: DishProductForm) => {
            const product = products.find(
              (product: Product) => product._id === curr.product
            );
            if (!product) {
              return curr;
            }

            return {
              calories:
                prev.calories +
                ((product?.calories || 0) / product.unit.defaultAmount) *
                  curr.amount,
              proteins:
                prev.proteins +
                ((product?.proteins || 0) / product.unit.defaultAmount) *
                  curr.amount,
              fats:
                prev.fats +
                ((product?.fats || 0) / product.unit.defaultAmount) *
                  curr.amount,
              carbs:
                prev.carbs +
                ((product?.carbs || 0) / product.unit.defaultAmount) *
                  curr.amount,
            };
          },
          { calories: 0, proteins: 0, fats: 0, carbs: 0 }
        ),
    [values, products]
  );

  return (
    <Table bordered striped responsive>
      <thead>
        <tr>
          <th>kcal</th>
          <th>B[g]</th>
          <th>T[g]</th>
          <th>W[g]</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{summary.calories.toFixed(2)}</td>
          <td>{summary.proteins.toFixed(2)}</td>
          <td>{summary.fats.toFixed(2)}</td>
          <td>{summary.carbs.toFixed(2)}</td>
        </tr>
      </tbody>
    </Table>
  );
};
