import React, { FC } from "react";
import { Table } from "react-bootstrap";

import { FullSummary } from "../../models/summary";

interface Props {
  data: FullSummary;
}

export const CaloriesSummaryTable: FC<Props> = ({ data }: Props) => {
  return (
    <Table bordered striped responsive>
      <thead>
        <tr>
          <th>Osoba</th>
          <th>kcal</th>
          <th>B[g]</th>
          <th>T[g]</th>
          <th>W[g]</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td className="text-capitalize">{key}</td>
            <td>{value.calories.toFixed(2)}</td>
            <td>{value.proteins.toFixed(2)}</td>
            <td>{value.fats.toFixed(2)}</td>
            <td>{value.carbs.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
