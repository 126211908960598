import { Col, Form, Row } from "react-bootstrap";
import React, { FC, useCallback, useMemo, useState } from "react";

interface Props {
  portionAsia: number;
  portionSzymek: number;
}

export const Calculator: FC<Props> = ({
  portionAsia,
  portionSzymek,
}: Props) => {
  const [dishWeight, setDishWeight] = useState();
  const onChange = useCallback(
    (e) => {
      setDishWeight(e.target.value);
    },
    [setDishWeight]
  );

  const fullPortion = portionAsia + portionSzymek;

  const asia = useMemo(() => (dishWeight! * portionAsia) / fullPortion || 0, [
    portionAsia,
    fullPortion,
    dishWeight,
  ]);

  const szymek = useMemo(
    () => (dishWeight! * portionSzymek) / fullPortion || 0,
    [portionSzymek, fullPortion, dishWeight]
  );

  return (
    <Row>
      <Col xs="4">
        <Form.Label>Waga:</Form.Label>
        <Form.Control type="number" onChange={onChange} />
      </Col>
      <Col xs="4">
        <Form.Label>Asia:</Form.Label>
        <Form.Control type="number" value={asia.toFixed()} disabled />
      </Col>
      <Col xs="4">
        <Form.Label>Szymek:</Form.Label>
        <Form.Control type="number" value={szymek.toFixed()} disabled />
      </Col>
    </Row>
  );
};
