import { format } from "date-fns";
import { pl } from "date-fns/locale";

const formatQueryDate = "yyyy-MM-dd";
const formatPolishDateWithDay = "eeee dd.MM.yy";

export const getQueryFormatDate = (date: Date): string => {
  return format(date, formatQueryDate, {
    locale: pl,
  });
};

export const getPolishFormatDate = (date: Date): string => {
  return format(date, formatPolishDateWithDay, {
    locale: pl,
  });
};
