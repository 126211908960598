import React, { FC } from "react";
import { Link } from "react-router-dom";

import { DietDayDish, Summary } from "../diet.model";
import { CategoryName } from "./CategoryName";
import { CaloriesSummaryTable } from "../../components";

interface Props {
  dishes: DietDayDish[];
  summary: Summary;
  id: string;
}

export const DietDay: FC<Props> = ({ dishes, summary, id }: Props) => {
  return (
    <div className="mb-5 mt-2">
      {dishes.map(({ time, dish }: DietDayDish) => (
        <div className="diet-dish-wrapper" key={dish._id}>
          <CategoryName time={time} />
          <Link to={`/diet/${id}/${time}`}>
            <div className="diet-dish">{dish.name}</div>
          </Link>
        </div>
      ))}
      <CaloriesSummaryTable data={summary} />
    </div>
  );
};
