import React, { FC, useCallback, useState } from "react";

interface Props {
  value: string;
  label: string;
  id: string;
}
export const DishIngredient: FC<Props> = ({ value, label, id }: Props) => {
  const [checked, setChecked] = useState(false);
  const onChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
    },
    [setChecked]
  );

  return (
    <>
      <input type="checkbox" id={id} value={value} onChange={onChange} />
      <label htmlFor={id} className={checked ? "checkbox-checked" : undefined}>
        {label}
      </label>
    </>
  );
};
