import React, { FC, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";

import { Element as ElementModel, ShoppingList } from "../shopping.model";

import "../List.scss";

interface Props {
  element: ElementModel;
  onCheck: (id: string, done: boolean) => Promise<ShoppingList>;
}

export const Element: FC<Props> = ({ element, onCheck }: Props) => {
  const [checked, setChecked] = useState(element.done);

  useEffect(() => setChecked(element.done), [element]);

  const onChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
      onCheck(element._id, event.target.checked).catch(console.error);
    },
    [setChecked, onCheck, element]
  );

  return (
    <Row>
      <Col xs="9" sm="8">
        <input type="checkbox" id={element._id} onChange={onChange} />
        <label
          htmlFor={element._id}
          className={classNames("d-flex", {
            "checkbox-checked": checked,
          })}
        >
          <div className="element-name-wrapper">
            <div className="element-name">{element.description}</div>
            <div className="element-amount">{`${element.amount.toFixed()} ${
              element.unit.shortcut
            }`}</div>
          </div>
        </label>
      </Col>
      <Col xs="3" sm="4">
        <Row>
          <Col xs="1">
            <button className="element-btn">
              <AiOutlineEdit />
            </button>
          </Col>
          <Col xs="1">
            <button className="element-btn">
              <AiOutlineDelete />
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
