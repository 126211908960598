import axios from "axios";
import { Product, ProductFormValues, Unit } from "./product.model";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const addProduct = async (
  product: ProductFormValues
): Promise<Product> => {
  const response = await axios.post(`${apiUrl}/products`, product);
  return response.data;
};

export const getProduct = async (id: string): Promise<Product> => {
  const response = await axios.get(`${apiUrl}/products/${id}`);
  return response.data;
};

export const getAllProducts = async (): Promise<Product[]> => {
  const response = await axios.get(`${apiUrl}/products`);
  return response.data;
};

export const editProduct = async (
  id: string,
  product: Product
): Promise<Product> => {
  const response = await axios.put(`${apiUrl}/products/${id}`, product);
  return response.data;
};

export const getUnits = async (): Promise<Unit[]> => {
  const response = await axios.get(`${apiUrl}/units`);
  return response.data;
};
