import { Form, FormCheck } from "react-bootstrap";
import React, { FC } from "react";
import { useField } from "formik";

interface Props {
  name: string;
  label: string;
}

export const Checkbox: FC<Props> = ({ name, label }: Props) => {
  const [{ value, ...field }, { error }] = useField(name);

  return (
    <Form.Group controlId={`field-${field.name}`}>
      <FormCheck
        className="pl-0"
        label={label}
        {...field}
        isInvalid={!!error}
        checked={value}
      />
      <Form.Control.Feedback>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
