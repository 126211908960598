import axios from "axios";
import { DishFormValues } from "../models/dish-form.model";
import { Dish } from "../models/dish.model";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const addDish = async (dish: DishFormValues): Promise<Dish> => {
  const response = await axios.post(`${apiUrl}/dishes`, dish);
  return response.data;
};

export const getDish = async (id: string): Promise<Dish> => {
  const response = await axios.get(`${apiUrl}/dishes/${id}`);
  return response.data;
};

export const getAllDishes = async (): Promise<Dish[]> => {
  const response = await axios.get(`${apiUrl}/dishes`);
  return response.data;
};

export const editDish = async (id: string, dish: Dish): Promise<Dish> => {
  const response = await axios.put(`${apiUrl}/dishes/${id}`, dish);
  return response.data;
};
