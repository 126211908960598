import { useLocation } from "react-router";
import { useMemo } from "react";

export const useQuery = <T extends { [key: string]: string }>(): T => {
  const { search } = useLocation();

  return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [
    search,
  ]) as T;
};
